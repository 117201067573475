<template>
    <div>
        <!-- Home Banner -->
        <div class="mx-2"></div>
        <section class="home-banner">
            <div class="container">
                <div class="title">
                    <h4>
                        {{ $t('Home.hero.title') }}
                        <span> {{ $t('Home.hero.titleSpan') }}</span>
                    </h4>
                    <p>{{ $t('Home.hero.subtitle') }}</p>
                    <a
                        href="https://apps.fortnox.se/fs/fs/login.php?relay=%23%2Fmarketplace%2Fapp-details%2F51We0QQmNTAE"
                        class="button"
                    >
                        {{ $t('Home.hero.startedBtn') }}
                        <img src="@/assets/mainImages/icons/arrow-right.svg" />
                    </a>
                </div>
            </div>
        </section>
        <!-- Home Banner End -->

        <section class="home-section-03 mx-2">
            <div class="container">
                <p>
                    {{ $t('Home.section.firstParagraph') }}
                    <span>{{ $t('Home.section.span') }}</span>
                </p>
            </div>
        </section>

        <section class="home-img-banner">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <img
                            src="@/assets/duplioAsset/duplioAssets/dashboard.png"
                        />
                    </div>
                </div>
            </div>
        </section>

        <!-- Home Pricing -->
        <section class="home-pricing">
            <div class="container">
                <div class="title mb-2">
                    <h2 v-text="$t('Home.pricing.title')" />
                    <br /><br />
                </div>
                <div class="d-flex justify-content-center mb-1">
                    <p
                        class="font-weight-bold h6"
                        v-text="$t('Home.pricing.subtitle')"
                    />
                </div>

                <b-container v-if="amountInvoices.length > 0">
                    <b-row align-h="center" class="" style="gap: 0.5rem">
                        <b-col cols="12" lg="3">
                            <v-select
                                :clearable="false"
                                v-model="selectTier"
                                label="label"
                                :reduce="label => label.value"
                                @input="changePrice"
                                :options="renderOptions(tierLang)"
                                :placeholder="$t(locale.amount)"
                                class="resource-selector d-inline-block"
                            />
                        </b-col>
                        <b-col cols="12" lg="3">
                            <v-select
                                :clearable="false"
                                v-model="selectPlan"
                                label="label"
                                :reduce="label => label.value"
                                @input="changePrice"
                                :placeholder="$t(locale.paymentPeriod)"
                                :options="$t(locale.periodPlan)"
                                class="resource-selector d-inline-block"
                            />
                        </b-col>
                    </b-row>
                </b-container>

                <div class="row mx-2 mt-5">
                    <div
                        v-for="(data, index) in pricing"
                        :key="index"
                        class="col-lg-4 mt-2"
                    >
                        <div
                            :class="data.cardClass"
                            class="d-flex flex-column"
                            style="justify-content: space-around"
                        >
                            <div class="top-action" style="gap: 1rem">
                                <h6 class="text-capitalize">
                                    {{ data.title }}
                                </h6>
                                <h4>
                                    <span>Fr. {{ data.price }} kr</span>/
                                    {{ $t('Home.pricing.month') }}
                                </h4>

                                <a
                                    href="https://apps.fortnox.se/fs/fs/login.php?relay=%23%2Fmarketplace%2Fapp-details%2F51We0QQmNTAE"
                                    :class="data.btnClass"
                                >
                                    {{ $t('Home.hero.startedBtn') }}
                                </a>
                            </div>

                            <ul>
                                <h4>{{ $t('Home.pricing.features') }} :</h4>
                                <li
                                    v-for="(item, index) in data.list"
                                    :key="index"
                                >
                                    <div class="icon">
                                        <i class="fa fa-check"></i>
                                    </div>
                                    {{ item }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Home Pricing End -->

        <!-- Home FAQ  -->
        <section class="home-faq" style="min-height: 390px">
            <div class="container">
                <div class="title">
                    <h2 v-text="$t('Home.faq.title')" />
                </div>

                <div class="row justify-content-center">
                    <div
                        v-for="(data, index) in faq"
                        :key="index"
                        class="col-lg-5 d-flex flex-column"
                    >
                        <b-card
                            v-for="(item, index) in data.data"
                            :key="index"
                            no-body
                            bg-variant="transparent"
                            class="shadow-0 mb-0"
                            style="box-shadow: none"
                        >
                            <b-card-header role="tab" header-tag="header">
                                <b-button
                                    block
                                    :aria-controls="item.id"
                                    class="p-0 bg-transparent"
                                    variant="transparent"
                                    @click="item.visible = !item.visible"
                                >
                                    <div class="d-flex" style="gap: 0.5rem">
                                        <div
                                            class="icon d-flex accodionIcon justify-content-center bg-secondaryColor"
                                        >
                                            <i
                                                v-if="item.visible"
                                                class="fa fa-angle-up"
                                            />
                                            <i
                                                v-else
                                                class="fa fa-angle-down"
                                            />
                                        </div>
                                        <p
                                            class="mb-0 pb-0 text-left font-weight-bold text-secondaryColor"
                                            style="font-size: 20px"
                                            v-text="item.title"
                                        />
                                    </div>
                                </b-button>
                            </b-card-header>
                            <b-collapse
                                :id="item.id"
                                visible
                                v-model="item.visible"
                                accordion="my-accordion"
                                role="tabpanel"
                                class="px-2"
                            >
                                <b-card-body
                                    class="pt-0 pl-0 ml-2"
                                    style="padding-left: 27px"
                                >
                                    <b-card-text
                                        class="text-secondaryColor"
                                        v-text="item.body"
                                    />
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </div>
            </div>
        </section>
        <!-- Home FAQ End -->

        <!-- Home Banner 02 -->
        <section class="home-banner-02">
            <div class="container">
                <div class="text">
                    <p>
                        {{ $t('Home.homeBanner02.paragraph1') }}
                        <span> {{ $t('Home.homeBanner02.span1') }}</span>
                        {{ $t('Home.homeBanner02.paragraph2') }}
                        <span>
                            {{ $t('Home.homeBanner02.span2') }}
                        </span>
                        {{ $t('Home.homeBanner02.paragraph3') }}
                    </p>
                    <a
                        href="https://apps.fortnox.se/fs/fs/login.php?relay=%23%2Fmarketplace%2Fapp-details%2F51We0QQmNTAE"
                        class="button"
                    >
                        {{ $t('Home.homeBanner02.action') }}
                        <img src="@/assets/mainImages/icons/arrow-right.svg" />
                    </a>
                </div>
            </div>
        </section>
        <!-- Home Banner 02 End -->
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import axios from 'axios';
import useAppConfig from '@core/app-config/useAppConfig';

export default {
    components: {
        vSelect,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            visible: false,
            selectTier: null,
            selectPlan: null,
            config: useAppConfig(),
            pricing: [],
            copyPricing: [],
            faq: this.$t('Home.faq.faqList'),
            amountInvoices: [],
            locale: {
                amount: 'Home.pricingSelect.amount',
                paymentPeriod: 'Home.pricingSelect.paymentPeriod',
                periodPlan: 'Home.pricingSelect.periodPlan',
            },
        };
    },
    computed: {
        tierLang() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_tier_name' : 'eng_tier_name';
            } else
                return this.getLang == 'sv' ? 'swe_tier_name' : 'eng_tier_name';
        },
        getLang() {
            return this.config.lang.value;
            // return localStorage.getItem('mainLang');
        },
    },
    methods: {
        changePrice() {
            let selectTier = this.selectTier;
            let selectPlan = this.selectPlan;
            let getPrice = JSON.parse(this.copyPricing);

            let newPrice = [];
            getPrice.forEach(element => {
                if (element.price !== 0) {
                    console.log(element);
                }
                newPrice.push({
                    ...element,
                    price: element.price == 0 ? element.price : element.price + selectTier + selectPlan,
                });
            });

            this.pricing = newPrice;
        },
        renderOptions(tierLang) {
            let getData = this.amountInvoices.map(element => {
                return {
                    label: element[tierLang],
                    value: element.add_on,
                };
            });

            return getData;
        },
        getLabelSelect() {
            axios
                .get(process.env.VUE_APP_TIER_INFO, {
                    transformRequest: (data, headers) => {
                        delete headers.Authorization;
                        return data;
                    },
                })
                .then(res => {
                    const {data} = res.data;
                    data.sort((a, b) => {
                        return a.level - b.level;
                    });

                    this.amountInvoices = data;
                });
        },
        getPricing() {
            axios
                .get(process.env.VUE_APP_SERVICE_PLAN, {
                    transformRequest: (data, headers) => {
                        delete headers.Authorization;
                        return data;
                    },
                })
                .then(res => {
                    const {data} = res.data;

                    data.sort((a, b) => {
                        return b.level - a.level;
                    });

                    let arrayDefault = [];
                    data.forEach((element, index) => {
                        this.pricing.push({
                            cardClass: index == 1 ? 'item active' : 'item',
                            title: element.plan_name,
                            price: element.price,
                            btnClass:
                                index == 0 && index == 1
                                    ? 'button'
                                    : 'button active',
                            list:
                                this.getLang == 'sv'
                                    ? element['eng_features']
                                    : element['swe_features'],
                        });

                        arrayDefault.push({
                            cardClass: index == 1 ? 'item active' : 'item',
                            title: element.plan_name,
                            price: element.price,
                            btnClass:
                                index == 0 && index == 1
                                    ? 'button'
                                    : 'button active',
                            list:
                                this.getLang == 'sv'
                                    ? element['eng_features']
                                    : element['swe_features'],
                        });
                    });

                    this.copyPricing = JSON.stringify(arrayDefault);
                });
        },
    },

    mounted() {
        this.getLabelSelect();
        this.getPricing();
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/firstlayout/home.scss';

.accodionIcon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: white;
    font-size: 18px;
}
.resource-selector {
    width: 100%;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
